<template>
    <modal-content ref="add-block-modal" :max-width="480" name="blocks" title="Add New Block" @close="closeModal" @before-close="onBeforeClose()">
        <template #content>
            <div class="bg-white rounded-b-lg flex-1 h-full flex overflow-y-auto max-w-full">
                <div v-if="loadingLists" class="flex justify-center items-center flex-1">
                    <Loader />
                </div>

                <ListView v-else :showSearch="true" :listItems="lists" class="max-w-full" :showBorder="false" @handleFilterResult="$emit('handleFilterResult', $event)">
                    <template #item="{ item }">
                        <BlockListItem :activeElement="activeElement" :data="item" @handleSelect="handleSelect($event)" />
                    </template>
                </ListView>
            </div>
        </template>
        <template #footer>
            <div class="flex w-full justify-end">
                <Button @click="$emit('AddNewBlock', activeElement)" class="btn-primary" :disabled="!activeElement" text="Add Block " />
            </div>
        </template>
    </modal-content>
</template>

<script>
import Button from "@/components/button";
import Loader from "@shared/loader";
import modalContent from "@shared/modal-content";
import BlockListItem from "@/pages/block-admin/components/BlockListItem.vue";
import ListView from "@shared/components/list-view";

export default {
    name: "add-block-modal",
    components: {
        Loader,
        Button,
        modalContent,
        BlockListItem,
        ListView,
    },
    data() {
        return {
            blockName: "",
            activeElement: null,
        };
    },
    props: {
        loadingLists: {
            type: Boolean,
            default: false,
        },
        lists: {
            type: Array,
            default: () => [],
        },
    },
    mounted() {
        this.closeModal();
    },
    methods: {
        closeModal() {
            this.$refs["add-block-modal"].hideModal();
        },
        showBlockModal() {
            this.blockName = "";
            this.$refs["add-block-modal"].showModal();
        },
        async handleSelect(block) {
            if (this.activeElement && block.id === this.activeElement.id) {
                this.activeElement = null;
            } else {
                this.activeElement = block;
            }
        },
        onBeforeClose() {
            this.$emit("before-close");
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-header {
    &__icon {
        fill: white;
        * {
            fill: white;
        }
    }
}
</style>
